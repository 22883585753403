import axios, { AxiosError, AxiosResponse } from 'axios';

import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/consts/queryKeys.ts';

export type useSearchQueryAutocompleteRequest = { q?: string };

export function useSearchQueryAutocomplete(params: useSearchQueryAutocompleteRequest) {
  return useQuery<AxiosResponse<string[]>, AxiosError>({
    enabled: !!params.q,
    queryFn: () => axios.get(`/search-service/seper/autocomplete`, { params }),
    queryKey: [queryKeys.search, params],
  });
}
